@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');



 @font-face {
        font-family: 'Adobe-Garamond-Pro-Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Adobe-Garamond-Pro-Regular'), url('Garamond-font/AGaramondPro-Regular.woff') format('woff');
        }

        @font-face {
            font-family: 'Adobe-Garamond-Semibold';
            font-style: normal;
            font-weight: normal;
            src: local('Adobe-Garamond-Semibold'), url('Garamond-font/Adobe-Garamond-Semibold-Regular.otf') format('otf');
            } 


@font-face {
    font-family: 'Adobe Garamond Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro Regular'), url('Garamond-font/AGaramondPro-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Adobe Garamond Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro Italic'), url('Garamond-font/AGaramondPro-Italic.woff') format('woff');
}


@font-face {
    font-family: 'Adobe Garamond Pro semi-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro semi-Bold'), url('Garamond-font/AGaramondPro-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Adobe Garamond Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro Bold Italic'), url('Garamond-font/AGaramondPro-BoldItalic.woff') format('woff');
} 




/*Font face*/
/*Custom Css*/
:root {
    /* --main-primary-color: #ff8080; */
    --main-primary-color: #d8b561;
    --MuiDocs-navDrawer-width: 0px;
}

a:hover {
    color: #000;
    text-decoration: none !important;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    color: #212123;
    height: 101%;
}

body {
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    /* -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none; */
}

ul.rcs_header_menu h6 a {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Adobe Garamond Pro semi-Bold' !important;
}

button {
    font-family: 'Adobe Garamond Pro Regular' !important;
}

/* Links
    -------------------------------------------------------------------------- */
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    text-decoration: none;
}

a:focus,
a:hover,
a:active {
    text-decoration: none;
}

a:focus,
a:active {
    outline: none;
}

p,
ul,
ol,
dl,
pre,
table,
blockquote {
    margin-bottom: 1.5rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul ul,
ol ol,
dl dl,
ul ol,
ul dl,
ol ul,
ol dl,
dl ul,
dl ol {
    margin-bottom: 0;
}

@media (min-width: 1440px) {
    html {
        font-size: 90%;
    }
}

/*Custom Css*/

/*iphone css zoom*/
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }
}

@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }
}

@media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }
}

/*iphone css zoom*/

/*scroll top*/
.gs_scroll_top,
.gs_scroll_top:focus,
.gs_scroll_top:hover,
.gs_scroll_top:active {
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 40px;
    right: 30px;
    cursor: pointer;
    z-index: 99;
}

.rcs_scroll_smooth {
    scroll-behavior: smooth !important;
}

/*scroll top*/
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #4a4a4a #f2f2f2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 10px;
    border: 2px solid #f2f2f2;
}

::selection {
    color: #fff;
    background: #000;
}

/* ===== Scrollbar CSS ===== */


.rcs_custome_container {
    width: 100%;
    max-width: 1440px !important;
    position: relative;
    display: block;
    margin: 0 auto;
}

/* .modal-header button span {
        font-size: 55px;
        font-weight: 100;
    } */
.modal-header button span {
    font-size: 45px;
    font-weight: 400;
}

.rcs_fill_button,
.rcs_fill_button:hover,
.rcs_fill_button:active,
.rcs_fill_button:focus {
    border-radius: 3px !important;
    text-transform: uppercase !important;
    padding: 10px 40px !important;
    /* font-weight: 500 !important; */
    font-family: 'Adobe Garamond Pro Regular' !important;
    font-size: 13px !important;
    outline: none !important;
    box-shadow: none !important;
    letter-spacing: 0.125rem !important;
}



/* .rcs_fill_button::before {
        position: absolute;
        top: 4px;
        left: 3px;
        right: 0;
        width: 90%;
        height: calc(100% - 8px);
        z-index: 1;
        content: "";
        margin: 0 auto 0;
    } */
.rcs_fill_light_button {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.rcs_outline_button {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.rcs_blank_button {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.rcs_loader {
    width: 20px !important;
    height: 20px !important;
    position: relative;
    top: 3px;
    right: -15px;
}

.rcs_top_heading h2,
.rcs_top_heading h1 {
    font-family: 'MTD AlFresco' !important;
    font-style: normal;
    text-shadow: none;
    line-height: 1.00;
    font-size: 35px;
    margin: 10px 0;
}

.rcs_filter_wrapper {
    width: 100%;
    position: relative;
}

.rcs_filter_loader {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 410px;
    z-index: 999999;
    border-radius: 50%;
    width: 55px !important;
    height: 55px !important;
    padding: 10px;
}

/* .modal-backdrop {
        z-index: 9999999 !important;
    }
    .modal {
        z-index: 99999999 !important;
    } */
.rcs_filter_loader1 {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 450px;
    z-index: 999999;
    border-radius: 50%;
    width: 55px !important;
    height: 55px !important;
    padding: 10px;
}

/* loader for list filter */
.rcs_filter_prod_wrapper {
    width: 100%;
    position: relative;
}

.rcs_filter_prod_loader {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 174px;
    z-index: 999999;
    border-radius: 50%;
    width: 42px !important;
    height: 42px !important;
    padding: 10px;
}

@media (max-width:575px) and (min-width:320px) {
    .rcs_fill_button::before {
        left: 0;
        width: 86%;
    }

    .rcs_filter_loader,
    .rcs_filter_loader1 {
        position: absolute;
        left: 68px;
        top: 287px;
        width: 55px !important;
        height: 55px !important;
    }

    .rcs_filter_prod_loader {
        top: 40px;
    }
}

@media (max-width:991px) and (min-width:768px) {
    .rcs_filter_loader {
        left: 40%;
        top: 400px;
    }
}

/*Custome Modal Css*/
.modal-header {
    padding: 10px 20px 5px;
}

.modal-title.h4 {
    font-size: 1.50rem;
    line-height: 1.5;
    letter-spacing: 0.125rem;
    font-weight: 500;
    font-weight: 400;
    text-transform: uppercase;
}

.rcs_cancel_button,
.rcs_cancel_button:hover,
.rcs_cancel_button:active,
.rcs_cancel_button:focus {
    border-radius: 0 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    padding: 10px 40px !important;
    font-size: 16px !important;
}

/* .rcs_cancel_button::before {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 0;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: 1;
        content: "";
    } */
.rcs_save_button,
.rcs_save_button:hover,
.rcs_save_button:active,
.rcs_save_button:focus {
    /* border-radius: 0 !important;
        text-transform: capitalize !important;
        padding: 10px 40px !important;
        font-family: 'Inter', sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important; */
    border-radius: 0 !important;
    text-transform: capitalize !important;
    padding: 10px 20px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.rcs_save_button1,
.rcs_save_button1:hover,
.rcs_save_button1:active,
.rcs_save_button1:focus {
    margin: 0 auto !important;
    display: block !important;
}

/* .rcs_save_button::before {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 0;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: 1;
        content: "";
    } */
.modal-body {
    padding: 20px 20px 0;
}

.modal-header .close {
    padding: 0 0.5rem 1rem;
    margin: -1.2rem -1rem -1rem auto;
}

.gs_news_form input::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.gs_news_form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.gs_news_form input::placeholder {
    color: #fff;
}

/*Custome Modal Css*/
.gs_loader1 .MuiCircularProgress-root {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 50px !important;
    height: 50px !important;
}

.gs_loader1 {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/*Diamonds By Rothschild btn css*/
/* button.rcs_fill_button-radius {
        text-transform: uppercase !important;
        padding: 8px 30px !important;
        background: #000;
        border-color: #000;
        border-radius: 3px !important;
    } */
.rcs_btn_rd-0 {
    border-radius: 0 !important;
}

.rcs_btn_shadow {
    box-shadow: 0px 3px 7px 0px #1212124f !important;
}

.rcs_customer_google_rating_star h4 {
    margin-right: 10px;
    margin-left: 5px;
    font-weight: bold;
    font-family: 'Montserrat' !important;
    margin-bottom: 0;
}

.rcs_customer_google_rating_star {
    display: flex;
    align-items: center;
}

.rcs_customer_review_btn button,
.rcs_customer_review_btn button:active,
.rcs_customer_review_btn button:hover,
.rcs_customer_review_btn button:focus {
    background: #1B1A4A !important;
    border: #1B1A4A !important;
    border-radius: 0 !important;
}

.rcs_customer_review_btn button:hover {
    background: #1B1A4A !important;
    border: #1B1A4A !important;
    border-radius: 0 !important;
    padding: 10px 20px !important;
    letter-spacing: inherit !important;
}

/*Diamonds By Rothschild btn css*/

/*Responsive css*/
.gs_myaccount.gs_order_success p {
    margin: 0;
}

.gs_myaccount.gs_order_success {
    text-align: center;
    margin: 40px 0;
}

@media (max-width:1199px) {

    .rcs_filter_prod_loader {
        display: none;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .rcs_cancel_button {
        padding: 10px 23px !important;
    }

    .modal-footer {
        display: block ruby;
    }
}

@media (min-width: 320px) and (max-width: 767px) {

    .rcs_top_heading h2,
    .rcs_top_heading h1 {
        font-size: 30px;
        margin: 10px 0 20px;
    }

    .modal-title.h4 {
        font-size: 16px;
    }

    .rcs_cancel_button,
    .rcs_cancel_button:hover,
    .rcs_cancel_button:active,
    .rcs_cancel_button:focus {
        padding: 10px 25px !important;
    }

    .modal-body .modal-footer {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .modal-footer {
        padding-right: 15px !important;
        display: inline-block !important;
    }

    .rcs_custom_home_container.container {
        max-width: 100% !important;
        width: 100% !important;
    }

    .rcs_make_an_appointment_modal_footer.modal-footer {
        text-align: center;
        width: 100%;
    }
}

/*Responsive css*/

.swal-button,
.swal-button:hover,
.swal-button:active,
.swal-button:focus {
    border-radius: 0 !important;
    text-transform: capitalize !important;
    padding: 10px 40px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    box-shadow: none;
}

.swal-button::before {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 0;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    z-index: 1;
    content: "";
}

.swal-footer {
    text-align: center !important;
}

.swal-title {
    font-size: 18px !important;
}

.MuiDrawer-root.MuiDrawer-modal {
    z-index: 9999999 !important;
}

.MuiDrawer-root.MuiDrawer-modal.css-y28f86 {
    z-index: 1200 !important;
}

a.whatsapp_float {
    position: fixed;
    bottom: 30px;
    right: 20px;
}

a.whatsapp_float img {
    width: 42px;
    z-index: 99;
}

.modal-open123 .fade.modal-backdrop.show {
    opacity: 0;
}

.rcs_about_our_history_video_text_content h4 {
    margin-bottom: 10px;
}

.rcs_about_our_history_video_text_content h4 a {
    color: #000000;
    cursor: pointer;
}

.rcs_bg_inner {
    background-image: url('../images/bg-inner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 5rem 0;
}

/* .rcs_bg_inner.rcs_jewelry_bg_img {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
} */

.rcs_jewelry_new_box {
    height: 300px;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-end;
    padding: 15px 20px;
    position: relative;
    overflow: hidden;
}

.rcs_jewelry_new_box .img1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
}

.rcs_jewelry_new_section {
    padding-bottom: 70px;
}
.rcs_jewelry_new_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 99;
}

/* .rcs_jewelry_new_title a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 99;
} */
.rcs_jewelry_new_title a h3 {
    margin: 0;
}
a.rcs_jewelry_new_box_a {
    color: white;
}
.rcs_jewelry_new_box:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,#fa0a0a00 55.56%,#0000004a);
    z-index: 9;
}
.rcs_jewelry_new_box .rcs_jewelry_new_box_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
    transform: scale(1);
}
.rcs_jewelry_new_box:hover .rcs_jewelry_new_box_img {
    transform: scale(1.1);
    transition: all 0.5s;
}

.rcs_bg_inner .rcs_breadcrumb ol {
    justify-content: center;
}

.rcs_bg_inner_engagement {
    background-image: url('../images/bg-engagement.png');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    padding: 7rem 0;
}

.rcs_bg_inner_engagement .rcs_breadcrumb ol {
    justify-content: center;
}
.rcs_modal_124 .modal-dialog .modal-content {
    border: none;
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .rcs_filter_loader {
        top: 280px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .rcs_bg_inner {
        padding: 3rem 0;
    }

    .rcs_bg_inner_engagement {
        padding: 3rem 0;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .rcs_bg_inner {
        background-image: url('../images/bg-inner-mob.png');
        padding: 3rem 0;
    }

    .rcs_bg_inner_engagement {
        background-image: url('../images/bg-engagement-mob.png');
        padding: 3rem 0;
    }
}

@media (min-width: 576px) {
    .rcs_login_modal .modal-dialog {
        max-width: 400px;
        margin: 1.75rem auto;
    }
}



.service-banner {
    padding: 70px 0;
    background: url('../images/banner-service.png') no-repeat center/cover;
}

.service-banner h1 {
    font-size: 52px;
    margin-bottom: 0px;
}

.service-banner p {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
}

.service-banner img {
    max-width: 100%;
}

.service-banner h5 {
    font-size: 18px;
    font-family: 'Poppins' !important;
    margin-bottom: 20px;
    font-weight: 600;
}

.heading_1 {
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.process-section ul li,
.offer-section ul li {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    padding-left: 25px;
    position: relative;
}

.process-section ul li:last-child,
.offer-section ul li:last-child {
    margin-bottom: 0;
}

.process-section ul li:before,
.offer-section ul li:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: #000;
    transform: rotate(45deg);
}

.process-section img,
.offer-section img {
    max-width: 100%;
}

.offer-section div[class*="col-"]:last-child {
    padding-left: 50px;
}

.btn_prime {
    min-width: 440px;
    background: #000;
    color: #FFF;
    border-radius: 0;
    font-size: 18px;
    height: 70px;
    line-height: 70px;
    margin: 0;
    padding: 0 20px;
    border: 1px solid #000;
}

.btn_prime:hover {
    background-color: #FFF;
    color: #000;
}

.team-section .team-item {
    position: relative;
    height: 600px;
    max-width: 410px;
}

.team-item img {
    height: 600px;
    max-width: 100%;
    object-fit: cover;
}

.team-item .content {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
}

.team-item:hover .content {
    opacity: 1;
    visibility: visible;
}

.team-item .content h3 {
    font-size: 30px;
    color: #FFF;
    margin: 0;
    font-weight: 300;
    text-transform: uppercase;
    z-index: 1;
}

.team-item .content a {
    font-size: 20px;
    color: #FFF;
    z-index: 1;
}

.team-item .content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
    transition: 0.3s all ease-in-out;
    backdrop-filter: blur(0);
}

.team-item:hover .content {
    backdrop-filter: blur(10px); 
}

.team-item .content:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #FFF;
    width: 90%;
    height: 90%;
}

.contact-sec {
    position: relative;
    margin-top: 35px;
    padding-left: 25px;
}

.contact-sec:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 80%;
    background-color: #CCC;
    transform: translateY(-50%);
}

.contact-sec h3 {
    color: #B3B3B3;
    font-size: 20px;
    font-weight: 600;
}

.contact-sec a,
.contact-sec p {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    display: block;
    line-height: 2;
}

.contact-sec img {
    vertical-align: middle;
    margin-right: -18px;
}

.contact-sec p {
    margin: 0;
}

.contact-sec span {
    text-indent: 23px;
    display: inline-block;
}

.contact-section .contact-form h2 {
    margin-bottom: 35px;
}

.contact-section .contact-form .MuiFormControl-root {
    margin-bottom: 15px;
}

.contact-form .btn-send {
    font-family: 'Poppins' !important;
    background-color: #000;
    color: #FFF;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 0;
}

.contact-section .container {
    margin-top: -220px;
}

.contact-section .container > div {
    display: inline-block;
    padding: 40px 70px;
    background-color: #FFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.countable-section {
    counter-reset: counter;
    list-style: none;
}

.countable-section h5 {
    font-size: 25px;
    font-weight: bold;
}

.countable-section p {
    font-size: 1rem;
    color: #808080;
    margin-bottom: 10px;
}

.countable-section p:last-child,
.countable-section li:last-child {
    margin-bottom: 0;
}

.countable-section p strong {
    font-weight: 600;
    font-style: italic;
}

.countable-section li {
    margin: 0 0 40px 0;
    padding-left: 110px;
    position: relative;
}

.countable-section li::before {
    counter-increment: counter;
    content: counters(counter, '.') '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 85px;
    height: 85px;
    background: #000;
    color: #FFF;
    font-size: 28px;
    text-align: center;
    line-height: 85px;
}

.example-section .example-wrapper .item {
    width: 300px;
    height: 220px;
    border: 1px solid #EDEDED;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
}

.example-section .example-wrapper .item:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    transform: translateY(-5px);
}

.example-section .example-wrapper .item p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin: 15px 0 0;
    padding: 0 60px;
}

.example-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 50px;
}

.diamond-education {
    padding: 70px 0;
    background: url('../../Assets/images/diamond-edu.png') no-repeat center/cover;
}

.diamond-education .container {
    position: relative;
}

.diamond-education .headingg p {
    color: #FFF;
    font-size: 20px;
}

.diamond-education .tab-pane h2 {
    color: #FFF;
    font-size: 36px;
    text-transform: uppercase;
}

.diamond-education .tab-pane p {
    font-size: 16px;
    color: #FFF;
    margin: 0;
}

.edu-tabs {
    border: none;
    background: #FFF;
    width: fit-content;
    padding: 5px;
    position: absolute;
    top: 0px;
    right: 16px;
}

.edu-tabs li button {
    background-color: #FFF;
    font-weight: 500;
    font-size: 18px;
    font-family: 'Poppins' !important;
    padding: 10px 25px;
    border-radius: 0 !important;
    border: none !important;
    color: #B3B3B3;
}

.edu-tabs li button.active {
    background-color: #000 !important;
    color: #FFF !important;
}

.diamond-education img {
    width: 100%;
}

.service-section {
    padding-bottom: 100px;
}

@media only screen and (max-width: 1500px) {
    .block-footer2 .form-newsletter2 {
        margin-bottom: 40px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .service-banner img {
        max-width: 100%;
    }

    .example-section .example-wrapper .item {
        width: 260px;
        height: 200px;
    }

    .example-section .example-wrapper .item p {
        padding: 0 30px;
    }

    .team-item img {
        width: 100%;
        height: 400px;
    }

    .team-section .team-item {
        height: 100%;
    }

    .team-item .content h3 {
        font-size: 26px;
    }

    .team-item .content a {
        font-size: 16px;
    }

    .contact-section .container > div {
        padding: 40px;
    }
}

@media only screen and (max-width: 992px) {
    .example-wrapper {
        grid-gap: 20px;
    }

    .team-item .content {
        padding: 0 25px;
    }

    .team-item .content h3 {
        font-size: 18px;
    }

    .team-item .content a {
        font-size: 14px;
        word-break: break-all;
    }

    .service-banner h1 {
        font-size: 40px;
    }

    .process-section img, .offer-section img {
        width: 100%;
        margin-bottom: 35px;
    }

    .offer-section div[class*="col-"]:last-child {
        padding-left: 16px;
    }

    .diamond-education .tab-content {
        margin-top: 150px;
    }

    .tab-content .tab-pane .row .col-lg-5 {
        margin-top: 30px;
        text-align: center;
    }

    .diamond-education img {
        width: fit-content;
        max-width: 100%;
    }

    .edu-tabs {
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        min-width: max-content;
    }

    .diamond-education {
        text-align: center;
    }

    .choose-us .row div[class*="col-"]:last-child {
        padding: 0 10rem;
    }
}

@media only screen and (max-width: 600px) {
    .service-banner h1 {
        font-size: 32px;
    }

    .heading_1 {
        font-size: 26px;
    }

    .countable-section li {
        padding: 110px 0 0;
    }

    .example-section .example-wrapper .item {
        width: 80%;
    }

    .team-item img {
        height: 100%;
    }

    .team-section .team-item {
        text-align: center;
        margin-bottom: 15px;
    }

    .team-section .team-item {
        height: 95%;
        width: 90%;
        margin: 0 auto;
    }

    .contact-section .container > div {
        padding: 40px 25px;
    }

    .contact-sec span {
        text-indent: 0;
        margin-left: 25px;
    }

    .contact-sec a, .contact-sec p {
        position: relative;
    }

    .contact-sec img {
        position: absolute;
        top: 6px;
    }

    .btn_prime {
        min-width: fit-content;
        width: 100%;
    }

    .choose-us .row div[class*="col-"] {
        margin-bottom: 30px;
        padding: 0 50px;
    }

    .choose-us .row div[class*="col-"]:last-child {
        margin-bottom: 0;
    }

    .choose-us .row div[class*="col-"]:last-child {
        padding: 0;
    }
}

.cart-final-btn a:hover {
    padding: 10px 40px !important;
}